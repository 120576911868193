import React from "react";
import { useEffect, useState } from "react";
import { UserAuth } from "../context/AuthContext";
import { supabase } from "../firebase";
const adminemail = process.env.REACT_APP_ADMIN1_EMAIL;
const Form = () => {
	const [users, setUsers] = useState([]);
	const [selectedUser, setSelectedUser] = useState("");
	const [id, setId] = useState([]);
	const [courses, setCourses] = useState({});
	const [data, setData] = useState({});
	const [selectedCourse, setSelectedCourse] = useState("");
	const [courseType, setCourseType] = useState("");
	const { session } = UserAuth();
	const [code, setCode] = useState(false);
	const [submitted, setSubmitted] = useState(false);

	const admin = () => {
		if (session?.user?.email === adminemail) {
			return true;
		}
		return false;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);
		const formObject = {};

		formData.forEach((value, key) => {
			formObject[key] = value;
		});

		const uniqueCode = formObject.cta;

			if (courseType === "cp") {
				formObject.cta = `https://com.rpy.club/cp/${uniqueCode}`;
			} else if (courseType === "wp") {
				formObject.cta = `https://com.rpy.club/wp/${uniqueCode}`;
			} else if (courseType === "cop") {
				formObject.cta = `https://com.rpy.club/cop/${uniqueCode}`;
			} else if (courseType === "pdp") {
				formObject.cta = `https://com.rpy.club/pdp/${uniqueCode}`;
			} else {
				formObject.cta = formObject.cta;
			}
	

		formObject.code = code;

		const { error } = await supabase
			.from("creators")
			.update(formObject)
			.eq("email", `${admin() ? selectedUser : session?.user?.email}`)
			.eq("course", `${selectedCourse}`);

		if (!error) {
			alert("Updated Successfully");
			setSubmitted(!submitted);
		} else {
			alert(error.message);
		}
	};

	useEffect(() => {
		if (admin()) {
			const fetchedUsers = [];
			const fetchedCourses = [];
			const fetchDoc = async () => {
				const { data, error } = await supabase.from("creators").select();
				if (data) {
					setData(data);
					data.forEach(
						(item) =>
							!fetchedUsers.includes(item.email) &&
							fetchedUsers.push(item.email)
					);

					setUsers(fetchedUsers);
					setSelectedUser(fetchedUsers[0]);
					data.forEach(
						(item) =>
							item.email === data[0].email && fetchedCourses.push(item.course)
					);
					setId(fetchedCourses);
					setCourses(data[0]);
					setSelectedCourse(data[0].course);
					setCourseType(data[0].course_type);
					setCode(data[0].code || false);
				} else {
					alert(error.message);
				}
			};
			fetchDoc();
		} else {
			const fetchedCourses = [];
			const fetchDoc = async () => {
				const { data, error } = await supabase
					.from("creators")
					.select()
					.eq("email", `${session?.user.email}`);

				if (data) {
					setData(data);
					data.forEach((item) => fetchedCourses.push(item.course));
					setId(fetchedCourses);
					setCourses(data[0]);
					setSelectedCourse(fetchedCourses[0]);
					setCourseType(data[0].course_type);
					setCode(data[0].code);
				} else {
					alert(error.message);
				}
			};
			fetchDoc();
		}
		console.log("rendered");
	}, [session?.user?.email, submitted]);

	const handleUserSelect = (e) => {
		setSelectedCourse(null);
		const email = e.target.value;
		setSelectedUser(email);
		let newCourses = [];
		data.forEach(
			(item) => item.email === email && newCourses.push(item.course)
		);
		setId(newCourses);
		setSelectedCourse(newCourses[0]);
		setCourses(data?.filter((item) => item.email === email)[0]);
		setCourseType(data?.filter((item) => item.email === email)[0].course_type);
		setCode(data?.filter((item) => item.email === email)[0].code);
	};

	const handleSelect = (e) => {
		setSelectedCourse(e.target.value);
		if (admin()) {
			setCourses(
				data?.filter(
					(item) =>
						item.email === selectedUser && item.course === e.target.value
				)[0]
			);
			setCourseType(
				data?.filter(
					(item) =>
						item.email === selectedUser && item.course === e.target.value
				)[0].course_type
			);
			setCode(
				data?.filter(
					(item) =>
						item.email === selectedUser && item.course === e.target.value
				)[0].code
			);
		} else {
			setCourses(data?.filter((item) => item.course === e.target.value)[0]);
			setCourseType(
				data?.filter((item) => item.course === e.target.value)[0].course_type
			);
			setCode(data?.filter((item) => item.course === e.target.value)[0].code);
		}
	};

	const handleCheck = (e) => {
		setCode(e.target.checked);
	};
	console.log(code);

	return (
		<>
			<section class="flex flex-col items-center justify-center gap-4 min-h-[100vh] md:pt-0 pt-4">
				<div class="  md:w-[55%] w-[90%] p-6 bg-white border border-gray-200 rounded-lg shadow  mx-auto">
					{admin() && (
						<div class="mb-6">
							<label
								for="users"
								class="block mb-2 text-base  text-gray-900  font-bold"
							>
								Users
							</label>
							<select
								id="users"
								defaultValue={selectedUser}
								value={selectedUser}
								name="users"
								class="custom-input"
								onChange={handleUserSelect}
							>
								{users?.map((item, i) => {
									return (
										<option id={i} value={item}>
											{item}
										</option>
									);
								})}
							</select>
						</div>
					)}
					<div class="mb-6">
						<label
							for="courses"
							class="block mb-2 text-base  text-gray-900  font-bold"
						>
							Select Offering
						</label>
						<select
							id="courses"
							defaultValue={selectedCourse}
							value={selectedCourse}
							name="courses"
							class="custom-input"
							onChange={handleSelect}
						>
							{id?.map((item, i) => {
								return (
									<option id={i} value={item}>
										{item}
									</option>
								);
							})}
						</select>
					</div>
					<div class="mb-6">
						<a className="text-blue-600" href={courses?.url}>
							{courses?.url}
						</a>
					</div>

					<form onSubmit={handleSubmit}>
						<div className="flex flex-wrap gap-2">
							{courses?.cta && (
								<div className="md:w-[49%] w-full">
									<div class="mb-6 ">
										<label for="cta" class="custom-label ">
											{courses.course_type
												? "Enter Payment Link Code"
												: "Enter Payment Link"}
										</label>
										<input
											type="text"
											defaultValue={
												courses.course_type
													? courses?.cta.split("/").pop()
													: courses.cta
											}
											key={courses?.cta}
											id="cta"
											placeholder="5000"
											name="cta"
											class="custom-input "
										/>
									</div>

									{courses.show_checkbox && (
										<>
											<div class="flex items-center mb-4 ">
												<input
													id="code"
													type="checkbox"
													name="code"
													checked={code}
													onChange={handleCheck}
													class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 "
												/>
												<label
													for="code"
													class="ms-2 text-base font-bold text-gray-900 dark:text-gray-300"
												>
													{code ? "Disable Coupon Code" : "Enable Coupon Code"}
												</label>
											</div>

											{code && (
												<div class="mb-6 ">
													<label
														for="coupon_value"
														class="block mb-2 text-sm font-medium text-gray-900 "
													>
														Enter Coupon Code{" "}
													</label>
													<input
														type="text"
														defaultValue={courses?.coupon_value}
														key={courses?.coupon_value}
														id="coupon_value"
														placeholder="EARLYBIRD"
														name="coupon_value"
														class="custom-input "
														required={code}
													/>
												</div>
											)}
										</>
									)}
								</div>
							)}
							{courses?.cta1 && (
								<div class="mb-6 md:w-[49%] w-full">
									<label for="cta1" class="custom-label ">
										Payment Link Code 2{" "}
									</label>
									<input
										type="url"
										defaultValue={courses?.cta1}
										key={courses?.cta1}
										id="cta1"
										placeholder="5000"
										name="cta1"
										class="custom-input "
									/>
								</div>
							)}
							{courses?.whatsapp && (
								<div class="mb-6 md:w-[49%] w-full">
									<label for="whatsapp" class="custom-label ">
										New Thank you link
									</label>
									<input
										type="url"
										defaultValue={courses?.whatsapp}
										key={courses?.whatsapp}
										id="whatsapp"
										placeholder="5000"
										name="whatsapp"
										class="custom-input "
									/>
								</div>
							)}

							{courses?.course_banner && (
								<div class="mb-6 md:w-[49%] w-full">
									<label for="course_banner" class="custom-label ">
										New Banner Link{" "}
									</label>
									<input
										type="url"
										defaultValue={courses?.course_banner}
										key={courses?.course_banner}
										id="course_banner"
										placeholder="5000"
										name="course_banner"
										class="custom-input "
									/>
								</div>
							)}

							{courses?.time && (
								<div class="mb-6 md:w-[49%] w-full">
									<label for="time" class="custom-label ">
										New End Time - HH:MM
									</label>
									<input
										id="time"
										defaultValue={courses?.time}
										key={courses?.time}
										type="time"
										name="time"
										required
										class="custom-input "
									/>
								</div>
							)}

							{courses?.date && (
								<div className="md:w-[49%] w-full">
									{" "}
									<label for="date" class="custom-label  w-full">
										New End Date - DD/MM/YYYY{" "}
									</label>
									<div class="relative mb-6  ">
										<div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none flex-1">
											<svg
												class="w-4 h-4 text-gray-500 "
												aria-hidden="true"
												xmlns="http://www.w3.org/2000/svg"
												fill="currentColor"
												viewBox="0 0 20 20"
											>
												<path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
											</svg>
										</div>
										<input
											datepicker
											type="date"
											name="date"
											key={courses?.date}
											id="date"
											defaultValue={courses?.date}
											required
											class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 "
											placeholder="01//01/2000"
										/>
									</div>
								</div>
							)}
							{/* <Datepicker type='text' defaultValue={data?.date} placeholder='Date' /> */}

							{courses?.price && (
								<div class="mb-6 md:w-[49%] w-full">
									<label for="price" class="custom-label ">
										New Price - (If required){" "}
									</label>
									<input
										type="text"
										defaultValue={courses?.price}
										key={courses?.price}
										id="price"
										placeholder="5000"
										name="price"
										class="custom-input "
									/>
								</div>
							)}
							{courses?.cut_price && (
								<div class="mb-6 md:w-[49%] w-full">
									<label for="cut_price" class="custom-label ">
										New Cut Price - (If required){" "}
									</label>
									<input
										type="text"
										defaultValue={courses?.cut_price}
										key={courses?.cut_price}
										id="cut_price"
										placeholder="5000"
										name="cut_price"
										class="custom-input "
									/>
								</div>
							)}
							{courses?.discount && (
								<div class="mb-6 md:w-[49%] w-full">
									<label for="discount" class="custom-label ">
										New Discount (₹)/(%) - (If required){" "}
									</label>
									<input
										type="text"
										defaultValue={courses?.discount}
										key={courses?.discount}
										id="discount"
										placeholder="5000"
										name="discount"
										class="custom-input "
									/>
								</div>
							)}
						</div>
						<button
							type="submit"
							class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto md:px-[50px] px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 md:float-right"
						>
							Submit
						</button>
					</form>
				</div>
			</section>
		</>
	);
};

export default Form;
